<template>
	<div class="videoUpload">
		<el-upload
			action=""
			list-type="picture-card"
			accept="video/mp4, video/ogg, video/flv,video/avi,video/wmv,video/rmvb"
			multiple
			:file-list="fileList"
			:before-upload="handleBeforeUpload"
			:http-request="uploadURL"
			:on-exceed="handleExceed"
			:class="{ hide: fileList.length >= maxCount || disabled }"
		>
			<i slot="default" class="el-icon-plus"></i>
			<div slot="file" slot-scope="{ file }">
				<video class="el-upload-list__item-thumbnail" :src="file.url" alt=""></video>
				<span class="el-upload-list__item-actions">
					<span class="el-upload-list__item-preview" @click="handleShowVideo(file)">
						<i class="el-icon-video-play"></i>
					</span>

					<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
						<i class="el-icon-delete"></i>
					</span>
				</span>
				<div class="videoMask" v-if="file.progress != 100">上传中...</div>
				<el-progress v-if="file.progress != 100" :percentage="progress" type="circle" :text-inside="true" :stroke-width="5" status="success">
				</el-progress>
			</div>
		</el-upload>
		<el-dialog :visible.sync="dialogVisible" append-to-body width="40%" style="text-align: center">
			<video :src="dialogImageUrl" alt="" autoplay class="video" controls="controls"></video>
		</el-dialog>
	</div>
</template>
<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
export default {
	name: 'MultiUpload',
	props: {
		// 图片属性数组
		value: {
			type: Array,
			default: () => [],
		}, // 最大上传图片数量
		maxCount: {
			type: Number,
			default: 6,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isShowDetail: {
			type: Boolean,
			default: false,
		},
		modulePath: {
			type: String,
			default: 'public',
		},
		operator: {
			type: String,
			default: 'zhangmt',
		},
	},
	data() {
		return {
			fileList: [],
			images: [],
			dialogVisible: false,
			dialogImageUrl: null,
			dialogImageName: '',
			num: 0,
			fileNum: 0,
			uploading: false,
			progress: 0,
		};
	},
	created() {
		// 上传组件显示列表
		this.fileList = this.value; // 图片保存数
	},
	watch: {
		value: {
			handler(val) {
				this.fileList = val.map((item) => {
					return {
						...item,
						progress: 100,
					};
				});
			},
			deep: true,
		},
	},
	methods: {
		// 自定义上传
		uploadURL(file) {
			let fileName = 'bernieri/' + `${this.operator}/${this.modulePath}/${getFileNameUUID()}` + '.mp4';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
					progress: (p) => {
						//获取进度条的值
						file.progress = p * 100;
						this.progress = p * 100;
					},
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.uploading = false;
						this.images.push({
							title: res.name,
							url: path,
							progress: file.progress,
						});
						this.num++;
						if (this.num === this.fileNum) {
							this.num = 0;
							this.fileNum = 0; // 这里如果 this.value.push(imageUrl) 这么写，vue会报出警告，大概意思是value作为props不应该在子组件中被修改 // 应该根据 value 得到新的值，而不能修改它，this.value.concat(imageUrl)也是可以的，concat方法返回新的数组
							this.fileList.push(...this.images);
							this.$emit('uploadVideo', this.fileList);
							this.images = [];
						}
					} else {
						this.$message.error('上传失败，请检查文件是否正确');
					}
				});
		},
		// 播放视频
		handleEditVideo(file) {
			this.editForm.url = file.url;
			this.editForm.uid = file.uid;
			this.editView = true;
		},
		// 编辑视频
		editVideo() {
			for (let i in this.fileList) {
				if (this.fileList[i].uid == this.editForm.uid) {
					this.fileList[i].url = this.editForm.url;
				}
			}
			this.submitFile();
		},
		submitFile() {
			this.$emit('submitImg', this.fileList);
		},
		// 移除视频
		handleRemove(file) {
			if (file.id) {
				this.$emit('deleteFile', file);
			}
			for (let i in this.fileList) {
				if (this.fileList[i].uid == file.uid) {
					this.fileList.splice(i, 1);
				}
			}
		},
		handleShowVideo(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleBeforeUpload() {
			this.uploading = true;
			this.fileNum++;
		},
		handleExceed() {
			// this.$message({
			// 	message: '最多只能上传' + this.maxCount + '张图片',
			// 	type: 'warning',
			// 	duration: 1000,
			// });
		},
	},
};
</script>
<style scoped lang="scss">
video {
	height: 148px;
	width: 148px;
}
.videoUpload {
	position: relative;

	.videoMask {
		width: 146px;
		height: 148px;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		border-radius: 6px;
		position: absolute;
		top: 0;
		text-align: center;
		line-height: 148px;
	}
}
.hide {
	/deep/ .el-upload--picture-card {
		display: none !important;
	}
}
</style>


